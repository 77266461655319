<template>
  <div id="app">
    <!-- <input class="values" v-model="values" type="text" /><span
      class="values2"
      >{{ values2 }}</span
    > -->
    <header class="top pcFlexShow">
      <div class="topC1"></div>
      <div class="topC2">
        <img height="47" src="@/assets/logo.png" />
        <span @click="tabCg('Home')" :class="{ activeCs: active == 'Home' }">Home</span>
        <span @click="tabCg('Mint')" :class="{ activeCs: active == 'Mint' }">Mint</span>
        <span @click="tabCg('Marketplace')" :class="{ activeCs: active == 'Marketplace' }">Marketplace</span>
        <span @click="tabCg('Guild')" :class="{ activeCs: active == 'Guild' }">Guild</span>
        <span @click="tabCg('PlayNow')" :class="{ activeCs: active == 'PlayNow' }">PlayNow</span>
      </div>
      <div class="topC3">
        <span>WhitePaper</span>
      </div>
    </header>
    <header class="mobileShow">
      <div class="mobileShowC1">
        <img @click="mobileCk" class="mobileShowImg1" src="./assets/menuIcon.png" />
        <div class="mobileMenuDiv" v-if="showMobleMenu">
          <p @click="tabCg('Home')" :class="{ MactiveCs: active == 'Home' }">
            Home
          </p>
          <p @click="tabCg('Mint')" :class="{ MactiveCs: active == 'Mint' }">
            Mint
          </p>
          <p @click="tabCg('Marketplace')" :class="{ MactiveCs: active == 'Marketplace' }">
            Marketplace
          </p>
          <p @click="tabCg('Guild')" :class="{ MactiveCs: active == 'Guild' }">
            Guild
          </p>
          <p @click="tabCg('PlayNow')" :class="{ MactiveCs: active == 'PlayNow' }">
            PlayNow
          </p>
        </div>
      </div>
      <div class="mobileShowC2">
        <img class="mobileShowImg2" src="@/assets/logo.png" />
      </div>
      <div class="mobileShowC3">WhitePaper</div>
    </header>

    <main class="main">
      <div class="mainC1 pcShow">
        <img class="img1" src="./assets/p1-bg.jpg" />
        <img class="img3" src="./assets/p1-bg-2.png" />
        <img class="imgCenter" src="./assets/p1/p1-3-01.png" />
        <img class="imgCenterL" src="./assets/p1/p1-01-1.png" />
        <img class="p1-01-2" src="./assets/p1/p1-01-2.png" />
        <img class="p1-01-3" src="./assets/p1/p1-01-2.png" />
        <img class="p1-01-4" src="./assets/p1/p1-01-4.png" />
        <img class="imgCenterR" src="./assets/p1/p1-02-1.png" />
        <img class="imgCenterRTxt" src="./assets/p1/p1-02-4.png" />
        <img class="p1-02-2" src="./assets/p1/p1-02-2.png" />
        <img class="p1-02-3" src="./assets/p1/p1-02-3.png" />
        <img class="p1-3-03-1" src="./assets/p1/p1-3-03-1.png" />
        <img class="p1-3-03-2" src="./assets/p1/p1-3-03-2.png" />
        <img class="p1-3-03-2" src="./assets/p1/p1-3-03-2.png" />
        <img class="p1-3-03-3" src="./assets/p1/p1-3-03-3.png" />
        <img class="p1-3-03-4" src="./assets/p1/p1-3-03-4.png" />
        <img class="p1-3-03-5" src="./assets/p1/p1-3-03-5.png" />
        <img class="p1-3-02" src="./assets/p1/p1-3-02.png" />
      </div>
      <div class="m_show m-mainC1">
        <img class="m_bg1" src="./assets/m_bg1.jpg" />
        <img class="imgCenter" src="./assets/p1/p1-3-01.png" />
        <img class="m-imgCenterL" src="./assets/p1/p1-01-1.png" />
        <img class="m-p1-01-2" src="./assets/p1/p1-01-2.png" />
        <img class="m-p1-01-3" src="./assets/p1/p1-01-2.png" />
        <img class="m-p1-01-4" src="./assets/p1/p1-01-4.png" />
        <img class="m-imgCenterR" src="./assets/p1/p1-02-1.png" />
        <img class="m-imgCenterRTxt" src="./assets/p1/p1-02-4.png" />
        <img class="m-p1-02-2" src="./assets/p1/p1-02-2.png" />
        <img class="m-p1-02-3" src="./assets/p1/p1-02-3.png" />
        <img class="p1-3-03-1" src="./assets/p1/p1-3-03-1.png" />
        <img class="p1-3-03-2" src="./assets/p1/p1-3-03-2.png" />
        <img class="p1-3-03-2" src="./assets/p1/p1-3-03-2.png" />
        <img class="m-p1-3-03-3" src="./assets/p1/p1-3-03-3.png" />
        <img class="m-p1-3-03-4" src="./assets/p1/p1-3-03-4.png" />
        <img class="m-p1-3-03-5" src="./assets/p1/p1-3-03-5.png" />
        <img class="m-p1-3-02" src="./assets/p1/p1-3-02.png" />
      </div>
      <div class="mainC2">
        <img class="p2-bg-1" src="./assets/p2-bg-1.jpg" />
        <template>
          <img :class="{ opcity1: showImgBefore }" class="p2-1-2" src="./assets/p2/p2-1-2.png">
          <div :class="{ opcity1: showImgBefore }" class="p2-01-3-2">
            <p>Cross the barriers,</p>
            <p>find the quickest path and win the treasure chest rewards</p>
          </div>
        </template>
        <template>
          <img :class="{ opcity1: !showImgBefore }" class="p2-2-2" src="./assets/p2/p2-2-2.png">
          <div :class="{ opcity1: !showImgBefore }" class="changeDiv1">
            <p>Use your imagination</p>
            <p>
              to create interesting levels in the game and set up more attractive
              rewards.
            </p>
          </div>
        </template>


      </div>
      <div class="mainC3 pcShow">
        <img class="img2" src="./assets/p3-bg-1.jpg" />
        <img class="p3-bg-2" src="./assets/p3-bg-2.png" />
        <img class="p3-1-1" src="./assets/p3/p3-1-1.png" />
        <img class="p3-2" src="./assets/p3/p3-2.png" />
        <img class="p3-1-9" src="./assets/p3/p3-1-9.png" />
        <img class="p3-1-2" src="./assets/p3/p3-1-2.png" />
        <img class="p3-1-3" src="./assets/p3/p3-1-3.png" />
        <img class="p3-1-4" src="./assets/p3/p3-1-4.png" />
        <img class="p3-1-5" src="./assets/p3/p3-1-5.png" />
        <img class="p3-1-6" src="./assets/p3/p3-1-6.png" />
        <img class="p3-1-7" src="./assets/p3/p3-1-7.png" />
        <div class="p3-a">
          The cute Thief will always bring you joy at unexpected moments, and
          you can buy a Thief of your own through mint or in Marketplace.
        </div>
        <img class="p3-1-10" src="./assets/p3/p3-1-10.png" />
      </div>
      <div class="m-mainC3 m_show">
        <img class="wap3-01" src="./assets/p3/wap3-01.png" />
        <img class="m-bg3" src="./assets/p3/m-bg3.jpg" />
        <img class="m-p3-1-7" src="./assets/p3/p3-1-7.png" />
        <div class="m-p3-a">
          The cute Thief will always bring you joy at unexpected moments, and
          you can buy a Thief of your own through mint or in Marketplace.
        </div>
      </div>

      <div class="mainC4">
        <img class="p4-bg" src="./assets/p4-bg.png" />
        <img class="p4-1" src="./assets/p4/p4-1.png" />
        <img class="p4-5-1" src="./assets/p4/p4-5.jpg" />
        <img class="p4-5-2" src="./assets/p4/p4-5.jpg" />
        <div class="p4-a">
          Prepare different types of keys to 'open' the door, and after
          successfully completing the level, you will get a treasure consisting
          of different crypto assets.
        </div>
        <img class="p4-4" src="./assets/p4/p4-4.png" />
        <img class="p4-bg-1-1" src="./assets/p4-bg-1-1.png" />
        <img class="p4-bg-1-2" src="./assets/p4-bg-1-2.png" />
        <img class="p4-2" src="./assets/p4/p4-2.png" />
      </div>
      <div class="mainC5 pcShow">
        <img class="p5-bg" src="./assets/p5-bg.jpg" />
        <img class="p5-2" src="./assets/p5/gai1.png" />
        <img class="p5-1" src="./assets/p5/p5-1.png" />
        <img class="p5-3" src="./assets/p5/p5-3.png" />
        <img class="p5-bg-1-3" src="./assets/p5-bg-1-3.png" />
        <img class="p5-bg-1-4" src="./assets/p5-bg-1-4.png" />
        <img class="p5-bg-1-2" src="./assets/p5-bg-1-2.png" />
        <img class="p5-bg-1-1" src="./assets/p5-bg-1-1.png" />
      </div>
      <div class="m-mainC5 m_show">
        <img class="m-p5-3" src="./assets/p5/p5-3.png" />
        <img class="m-p5-1" src="./assets/p5/p5-1.png" />
        <img class="m-p5-2" src="./assets/p5/gai1.png" />
        <img class="m-bg5" src="./assets/p5/m-bg5.jpg" />
      </div>
      <div class="mainC6 pcShow">
        <img class="p6-bg" src="./assets/p6-bg.png" />
        <img class="p6-1-2" src="./assets/p6/p6-1-2.png" />
        <div class="p6-a">
          <div>
            <p>STAGE 1</p>
            <p>
              • Game Prototype<br />
              • Private Sale Open<br />
              • Marketing Partnerships<br />
              • Social Media & Community
            </p>
          </div>
          <div>
            <p>STAGE 2</p>
            <p>
              • Genisis Thief NFT Sale<br />
              • Token Airdrop<br />
              • Dex Listing<br />
              • Stacking Listing
            </p>
          </div>
          <div>
            <p>STAGE 3</p>
            <p>
              • Mobile App<br />
              • ThiefDAO setup<br />
              • Assets cross-chain<br />
              • Public Gameplay Access
            </p>
          </div>
          <div>
            <p>STAGE 4</p>
            <p>
              • UGC&DLC Tools release<br />
              • Background Story publish<br />
              • InGame Marketplace<br />
              • New Realm Access
            </p>
          </div>
        </div>
        <img class="p6-1-1" src="./assets/p6/p6-1-1.png" />
        <img class="p6-1-3" src="./assets/p6/p6-1-3.png" />
        <img class="p6-1-7" src="./assets/p6/p6-1-7.png" />
        <img class="p7-bg-1" src="./assets/p7-bg-1.png" />
      </div>
      <div class="m-mainC6 m_show">
        <img class="m-bg6" src="./assets/p6/m-bg6.jpg" />
        <img class="wap6-02" src="./assets/p6/wap6-02.png" />
        <img class="m-p6-1-1" src="./assets/p6/p6-1-1.png" />
        <div class="m-p6-a">
          <div>
            <p>STAGE 1</p>
            <ul>
              <li>Game Prototype</li>
              <li>Private Sale Open</li>
              <li>Marketing Partnerships</li>
              <li>Social Media & Community</li>
            </ul>
          </div>
          <div>
            <p>STAGE 2</p>
            <ul>
              <li>Genisis Thief NFT Sale</li>
              <li>Token Airdrop</li>
              <li>Dex Listing</li>
              <li>Stacking Listing</li>
            </ul>
          </div>
          <div>
            <p>STAGE 3</p>
            <ul>
              <li>Mobile App</li>
              <li>ThiefDAO setup</li>
              <li>Assets cross-chain</li>
              <li>Public Gameplay Access</li>
            </ul>
          </div>
          <div>
            <p>STAGE 4</p>
            <ul>
              <li>UGC&DLC Tools release</li>
              <li>Background Story publish</li>
              <li>InGame Marketplace</li>
              <li>New Realm Access</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mainC7">
        <img class="p7-bg-2" src="./assets/p7-bg-2.jpg" />
        <img class="p7-1-3" src="./assets/p7/p7-1-3.png" />
        <div class="p7-a-1">CRYPTO THIEF</div>
        <div class="p7-a-2">believes everyone steals</div>
        <img class="p7-1-2" src="./assets/p7/p7-1-2.png" />
        <img class="p7-1-1" src="./assets/p7/p7-1-1.png" />
        <div class="p7-3">
          <span @click="jump(1)"></span>
          <span @click="jump(2)"></span>
          <span @click="jump(3)"></span>
        </div>
        <p class="foot">COPYRIGHT 2023 THIEF CLUB LTD. ALL RIGHTS RESERVED</p>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      active: "Home",
      showMobleMenu: false,
      showImgBefore: false,
      // values: "",
      // values2: "",
    };
  },
  methods: {
    tabCg(tab) {
      this.active = tab;
      this.showMobleMenu = !this.showMobleMenu;
    },
    mobileCk() {
      this.showMobleMenu = !this.showMobleMenu;
    },
    jump(type) {
      if (type == 1) {
        location.href = "https://www.baidu.com/";
      }
      if (type == 2) {
        location.href = "https://www.zhihu.com/signin?next=%2F";
      }
      if (type == 3) {
        location.href = " https://www.163.com/";
      }
    },
  },
  mounted() {
    setTimeout(() => {
      //手动触发重置尺寸事件，不然有问题
      var event = new Event("resize");
      window.dispatchEvent(event);
    }, 300);
    setInterval(() => {
      this.showImgBefore = !this.showImgBefore;
    }, 5000);
  },
  // watch: {
  //   values(val) {
  //     this.values2 = val * 2.27;
  //   },
  // },
};
</script>

<style lang="scss">
.values {
  position: fixed;
  top: 100px;
  left: 100px;
  z-index: 9999;
}

.values2 {
  position: fixed;
  top: 100px;
  left: 200px;
  z-index: 9999;
}

.m-mainC1 {
  display: none !important;
}

.m-mainC3 {
  position: relative;
  height: 2550px !important;
  display: none !important;
}

.m_show {
  display: none;
}

.wap3-01 {
  position: absolute;
  display: inline-block;
  width: 2560px;
  height: 2310px;
  z-index: 22;
  top: 250px;
}

.m-bg3 {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 2560px;
  height: 2550px;
  z-index: 1;
}

.m-mainC5 {
  width: 2560px;
  height: 2400px;
  display: none !important;

  img {
    display: inline-block;
  }

  .m-p5-1 {
    display: inline-block;
    position: absolute;
    width: 400px;
    left: 1100px;
    z-index: 9;
    top: 820px;
    animation: p5-1 ease 1s infinite;
  }

  .m-p5-2 {
    display: inline-block;
    position: absolute;
    width: 2060px;
    left: 250px;
  }

  .m-p5-3 {
    width: 2260px;
    left: 150px;
    top: -100px;
    display: inline-block;
    position: absolute;
    // animation: p5-3 10s infinite;
    // animation-timing-function: cubic-bezier(0, 0, 1, 1);
  }

  .m-bg5 {
    position: absolute;
    width: 2560px;
    height: 2400px;
    z-index: -1;
    left: 0;
    top: 0;
  }
}

.m-mainC6 {
  width: 2560px;
  height: 3100px;
  display: none !important;

  .wap6-02 {
    position: absolute;
    left: 0;
    top: 0;
    width: 2560px;
  }

  .m-bg6 {
    width: 2560px;
    height: 3100px;
    position: absolute;
    z-index: -1;
  }

  .m-p6-a {
    position: absolute;
    width: 2560px;
    z-index: 10;
    font-size: 55px;
    top: 700px;

    ul {
      padding-left: 120px;
    }

    div {
      width: 1100px;
      display: inline-block;
      vertical-align: top;
      margin-left: 120px;
      height: 1050px !important;

      p {
        font-weight: bold;
        font-size: 110px !important;
        background-image: url(./assets/p6/wap6-01.png);
        background-size: 100% 100%;
        padding-bottom: 50px;
        padding-left: 130px;
      }

      li {
        font-weight: bold;
        font-size: 80px !important;
      }
    }
  }

  .m-p6-1-1 {
    position: absolute;
    width: 244px;
    top: 162px;
    left: 200px;
    animation: p6-1-1 1.8s ease-in-out infinite;
  }
}

.pcShow {
  display: block !important;
}

.pcFlexShow {
  display: flex !important;
}

// @media screen and (max-width: 800px) {

// }
@media screen and (max-width: 1290px) {
  .pcShow {
    display: none !important;
  }

  .pcFlexShow {
    display: none !important;
  }

  .m_show {
    display: block !important;
  }

  .p2-01-3-1 {
    left: 1650px !important;
  }

  // .p2-02-2-1{
  //   width: 1242px !important;
  //   height: 839px !important;
  // }

  .m-p3-1-7 {
    position: absolute;
    width: 268px;
    right: 77px;
    top: 240px;
    animation: p3-1-7 3s ease infinite;
    z-index: 10;
  }

  .changeDiv1 {
    width: 1430px !important;
    height: 839px !important;
    left: 140px !important;

    p:nth-child(1) {
      padding-left: 60px;
      color: #fff;
      font-size: 75px;
      font-weight: bolder;
    }

    p:nth-child(2) {
      padding: 0;
      margin: 0;
      width: 1400px;
      color: #fff;
      font-size: 35px;
      transform: scale(0.85);
      margin-left: -70px;
      letter-spacing: -4px !important;
    }
  }

  .m-mainC1 {
    padding-top: 100px;
    height: 2000px;
    position: relative;

    img {
      display: inline-block;
    }

    .m-imgCenterL {
      position: absolute;
      width: 171px;
      left: -180px;
      top: 800px;
      animation-delay: 2s;
      animation: m-imgCenterL 2s 1;
      animation-timing-function: cubic-bezier(0, 0, 1, 1);
      animation-fill-mode: forwards;
    }

    .m-p1-01-2 {
      position: absolute;
      width: 24px;
      left: 308px;
      top: 770px;
      z-index: 99;
      animation: p1-01-3 0.3s linear 1;
      animation-delay: 2s;
      opacity: 0;
      animation-fill-mode: forwards;
    }

    .m-p1-01-3 {
      position: absolute;
      width: 35px;
      left: 240px;
      top: 720px;
      z-index: 99;
      opacity: 0;
      animation: p1-01-3 0.3s linear 1;
      animation-delay: 2.5s;
      animation-fill-mode: forwards;
    }

    .imgCenter {
      width: 2100px !important;
      margin-left: -1050px !important;
      top: 420px !important;
    }

    .m_bg1 {
      display: inline-block;
      width: 2560px;
      height: 2000px;
      top: 420px;
    }

    .m-p1-01-4 {
      position: absolute;
      width: 684px;
      left: -700px;
      animation: m-p1-01-4 1s linear 1;
      animation-delay: 2s;
      animation-fill-mode: forwards;
      top: 330px;
    }

    .p1-3-03-1 {
      position: absolute;
      width: 320px;
      top: 1120px;
      left: 450px;
    }

    .p1-3-03-2 {
      position: absolute;
      width: 240px;
      top: 900px;
      left: 750px;
      animation: p1-3-03-2 3s infinite;
    }

    .m-p1-3-03-3 {
      position: absolute;
      width: 160px;
      left: 930px;
      top: 1050px;
      animation: p1-3-03-3 4s infinite;
    }

    .m-p1-3-03-4 {
      position: absolute;
      width: 160px;
      top: 1200px;
      left: 900px;
      animation: p1-3-03-4 2s infinite;
    }

    .m-p1-3-03-5 {
      position: absolute;
      width: 140px;
      top: 1200px;
      left: 1200px;
      animation: p1-3-03-5 2.5s infinite;
    }

    .m-p1-3-02 {
      position: absolute;
      width: 340px;
      top: 1135px;
      left: 1670px;
    }

    .m-imgCenterR {
      position: absolute;
      width: 164px;
      right: 400px;
      top: 790px;
      animation: imgCenterR 2s linear 1;
      animation-fill-mode: forwards;
    }

    .m-imgCenterRTxt {
      position: absolute;
      width: 698px;
      right: -700px;
      top: 340px;
      animation: m-imgCenterRTxt 1s linear 1;
      animation-delay: 2s;
      animation-fill-mode: forwards;
    }

    .m-p1-02-2 {
      width: 30px;
      position: absolute;
      right: 410px;
      top: 760px;
      opacity: 0;
      animation: p1-02-3 0.3s linear 1;
      animation-delay: 2s;
      opacity: 0;
      animation-fill-mode: forwards;
    }

    .m-p1-02-3 {
      width: 44px;
      position: absolute;
      left: 2180px;
      top: 720px;
      opacity: 0;
      animation: p1-02-3 0.3s linear 1;
      animation-delay: 2.3s;
      opacity: 0;
      animation-fill-mode: forwards;
    }

    .m-p3-2 {
      width: 2560px;
      left: 0;
      top: 0;
    }
  }

  .mobileShow {
    top: 0;
    left: 0;
    width: 2560px;
    background-color: #000;
    position: fixed;
    display: flex !important;
    justify-content: justify-content;
    height: 200px !important;
    z-index: 999;

    .mobileShowImg1 {
      display: inline-block;
      height: 100px;
      margin-top: 40px;
      margin-left: 40ppx;
    }

    .mobileShowImg2 {
      display: inline-block;
      height: 120px;
      margin-top: 30px;
    }

    >div {
      color: #fff;
      flex: 1;
      text-align: center;
      padding: 10px;
    }

    .mobileShowC1 {
      cursor: pointer;
      text-align: left;
      padding-left: 50px;
      position: relative;
    }

    .mobileShowC3 {
      padding-right: 50px;
      text-align: right;
      font-size: 80px;
      margin-top: 40px;
    }

    .mobileMenuDiv {
      padding: 0 40px;
      min-width: 460px;
      background-color: #000;
      position: absolute;
      top: 200px;
      left: 0;
      font-size: 40px;
      z-index: 9999;

      p {
        text-align: center;
        height: 200px;
      }
    }

    .MactiveCs {
      color: orange;
    }
  }

  .mainC6 {
    position: relative;
    height: 1840px !important;
  }

  .p6-bg {
    width: 2560px;
    top: -300px !important;
    height: 2240px !important;
  }

  .p6-a {
    width: 2560px;
    top: 500px;
    right: 50px;
    position: absolute;
    display: flex;
    z-index: 99;
    font-weight: bold;
    flex-wrap: wrap;
    // padding-left: 240px !important;

    >div {
      width: 1120px !important;
      margin-right: 20px;
    }
  }

  .p2-01-3-2 {
    width: 1100px !important;
    left: 1250px !important;
    text-align: center;

    p:nth-child(2) {
      text-align: left;
      width: 1160px !important;
      font-size: 80px !important;
    }
  }

  .m-p3-a {
    position: absolute;
    top: 1300px;
    line-height: 170px;
    left: 310px;
    width: 1415px;
    font-size: 90px;
    z-index: 10;
    font-weight: bold;
  }

  .p4-1 {
    top: 65px !important;
  }

  .p4-5-1 {
    top: 481px !important;
    z-index: 99;
  }

  .p4-4 {
    top: 227px !important;
  }

  .p4-a {
    line-height: 100px;
    font: bold !important;
    width: 1701px !important;
    left: 411px !important;
    top: 580px !important;
    font-size: 80px !important;
  }

  .p2-01-1-2 {
    width: 1500px !important;
    left: 123px !important;
  }


  .p2-01-3 {
    width: 1500px !important;
    right: 103px !important;
  }

  .mainC6 {
    line-height: 95px;
    height: 2540px !important;
  }

  .p6-bg {
    top: -340px !important;
    height: 2850px !important;
  }
}
</style>
